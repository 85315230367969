export const InfosysitemgalleryImg = [
  {
    id: 1,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/0.jpeg",
    alt: "",
  },
  {
    id: 2,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/1.jpg",
    alt: "",
  },
  {
    id: 3,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/2.jpg",
    alt: "",
  },
  {
    id: 4,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/3.jpg",
    alt: "",
  },
  {
    id: 5,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/4.jpg",
    alt: "",
  },
  {
    id: 6,
    img: '"https://cdn-prod.heartfulness.org/FBH/infosys/5.jpg',
    alt: "",
  },
  {
    id: 7,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/6.jpg",
    alt: "",
  },
  {
    id: 8,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/7.jpg",
    alt: "",
  },
  {
    id: 9,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/8.jpg",
    alt: "",
  },
  {
    id: 10,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/9.jpg",
    alt: "",
  },
  {
    id: 11,
    img: "https://cdn-prod.heartfulness.org/FBH/infosys/10.jpg",
    alt: "",
  },
];
