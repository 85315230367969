import React from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import Photovideo from "components/infosys/videoplayer";

import Connectvideo from "components/infosys/connectvideo";

export default function Videophotograpyinfosys() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return <div className="carousel-button-group"></div>;
  };

  return (
    <div className="recent-plantation-section bosch-recent-plant hyd-recent-plant  zscalar-plantation-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pad-left0">
            <div className="section-title">
              <h3
                className="title-theme-heading infosys-theme-blue"
                style={{ textAlign: "left" }}
              >
                {" "}
                Event Video Gallery{" "}
              </h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="section pad-photography1">
            <div className="carousel-section">
              <Carousel
                arrows={true}
                autoPlaySpeed={3000}
                draggable
                infinite
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                renderButtonGroupOutside={true}
                swipeable
                responsive={responsive}
                customButtonGroup={<ButtonGroup />}
              >
                <div className="mg-bottom20-mobile zscaler-video-slide">
                  {/* <img src={bangaluruvillage} className='img-fluid' alt='Shanthivanam' /> */}
                  <Photovideo />
                  {/* <div
                    className="card-body where-body zscaler-gthaptik zcaler-body-box"
                    style={{ height: "80px" }}
                  ></div> */}
                </div>
                <div className="mg-bottom20-mobile zscaler-video-slide">
                  <Connectvideo />
                  {/* <div
                    className="card-body where-body zscaler-gthaptik zcaler-body-box"
                    style={{ height: "80px" }}
                  ></div> */}
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
