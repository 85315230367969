import React from "react";
import { Modal } from "react-bootstrap";
import lakeview from "assets/images/infosys/lakeview.png";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/8khJ662aVpE"
            title=""
            description=""
            alt="A new consciousness is emerging around the world, our human story is transitioning. "
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width="100%"
            height="410px"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Photovideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div
        onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex="0"
      >
        {/* <StaticImage
                  src="../images/prayer-video.png"
                  className="img-fluid"
                  alt="About Daaji"
                  placeholder="blurred"
                /> */}

        <img className="img-fluid" src={lakeview} alt="Lake View" />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Photovideo;
