import increasewater from "assets/images/infosys/increse-water.png";
import rechargeneibourhood from "assets/images/infosys/recharge-neibourhood.png";
import biodiversity from "assets/images/infosys/attract-biodiversity.png";
import foodsecurity from "assets/images/infosys/food-secreisty.png";
import walkingtrack from "assets/images/infosys/waling-track.png";

export const objectforestinfosys = [
  {
    id: 1,
    img: increasewater,
    alt: "preventflooding",
    title: "Increase water retention and prevent flooding",
  },

  {
    id: 2,
    img: rechargeneibourhood,
    alt: "Neighborhoodborewell",
    title: "Recharge Neighborhood borewells",
  },
  {
    id: 3,
    img: biodiversity,
    alt: "Biodiversity",
    title: "Attract Biodiversity",
  },
  {
    id: 4,
    img: foodsecurity,
    alt: "Foodsecurity",
    title: "Food security for local communities",
  },
  {
    id: 5,
    img: walkingtrack,
    alt: "walkingtrack",
    title: "Provide walking track for villagers",
  },
];
