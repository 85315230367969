import React from "react";

import Infosyslayout from "../infosyslayout/index";

import SEO from "components/seo/index";

import Infosysbanner from "components/infosys/banner";

import Objectforestinfosys from "components/infosys/objectiveforest";

import Projectexecuteinfosys from "components/infosys/project-execute";

import RecentPlantationsinfosys from "components/infosys/recentplantation";

import SimpleReactLightbox from "simple-react-lightbox";

import Videophotograpyinfosys from "components/infosys/video-photograpy";

import Wherewhenchikka from "components/infosys/where-when";

const infosysplantation = () => {
  return (
    <>
      <SEO title="chikka_timmasandra Forest Plantation Drive" />
      <Infosyslayout>
        <section id="zscaler-banner">
          <Infosysbanner />
        </section>

        <section id="objectives-forests">
          <Objectforestinfosys />
        </section>

        <section id="where-when">
          <Wherewhenchikka />
        </section>

        <section id="project-execute">
          <Projectexecuteinfosys />
        </section>

        <section id="video-photography">
          <Videophotograpyinfosys />
        </section>

        {
          <SimpleReactLightbox>
            <section id="recentplantation">
              <RecentPlantationsinfosys />
            </section>
          </SimpleReactLightbox>
        }
      </Infosyslayout>
    </>
  );
};

export default infosysplantation;
