import React, { useEffect, useRef } from "react";

import fbhgreenLogo from "assets/images/logo/FBH-Final-Logo.png";

// import infosyslogo from "assets/images/infosys/logo.png";

import { Navbar, Nav } from "react-bootstrap";

import { Link } from "gatsby";

const Header = ({ isSticky }) => {
  const ref = useRef(null);

  const scrollToSection = () => {
    document.querySelectorAll("a.scroller").forEach(function (a) {
      a.addEventListener("click", function (event) {
        if (event.target.getAttribute("href")) {
          event.preventDefault();
          const hash = event.target.getAttribute("href");
          const hashSection = hash.split("/#").pop();
          const scrollTarget = document.querySelector(`#${hashSection}`);
          const headerHeight = 60;
          if (scrollTarget) {
            window.scrollTo({
              top: scrollTarget.offsetTop - headerHeight,
              behavior: "smooth",
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    scrollToSection();
    if (isSticky) {
      const handleScroll = () => {
        if (ref.current && ref.current.classList) {
          if (window.scrollY > 10) {
            ref.current.classList.add("sticky");
          } else {
            ref.current.classList.remove("sticky");
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", () => handleScroll);
      };
    }
  }, []);

  return (
    <header
      id="header"
      className={`header-section ${isSticky ? "" : "sticky"}`}
      ref={ref}
    >
      <div className="top-menu infosysmenu">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand>
                  <span className="mobi-logo">
                    {/* <Link to="/infosys">
                      <img src={infosyslogo} alt="heartfulness Logo" />
                    </Link> */}
                  </span>
                  {/* <span className="zscaler-vertical-line"> </span> */}
                  <span className="heartfulness-logo">
                    <Link to="/">
                      <img src={fbhgreenLogo} alt="FBH Logo" />
                    </Link>
                  </span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  {isSticky ? (
                    <Nav className="mr-auto my-2 my-lg-0">
                      <Nav.Link href="/#zscaler-banner" className="scroller">
                        About
                      </Nav.Link>
                      <Nav.Link
                        href="/#objectives-forests"
                        className="scroller"
                      >
                        Objectives
                      </Nav.Link>
                      <Nav.Link href="/#recentplantation" className="scroller">
                        Events
                      </Nav.Link>
                      {/* <Nav.Link href='/#projectsunique' className='scroller'>
                        Why is this project unique
                      </Nav.Link> */}

                      <Nav.Link href="/#project-execute" className="scroller">
                        Project Execution
                      </Nav.Link>

                      <Nav.Link href="/#contact" className="scroller">
                        Contact
                      </Nav.Link>
                      {/* <Link to='/faqs' className='nav-link'>
                        FAQs
                      </Link> */}
                    </Nav>
                  ) : (
                    <Nav className="mr-auto my-2 my-lg-0">
                      <Nav.Link href="/#zscaler-banner" className="scroller">
                        About
                      </Nav.Link>
                      <Nav.Link
                        href="/#objectives-forests"
                        className="scroller"
                      >
                        Objectives
                      </Nav.Link>
                      <Nav.Link href="/#recentplantation" className="scroller">
                        Events
                      </Nav.Link>
                      {/* <Nav.Link href='/#projectsunique' className='scroller'>
                        Why is this project unique
                      </Nav.Link> */}

                      <Nav.Link href="/#project-execute" className="scroller">
                        Project Execution
                      </Nav.Link>

                      <Nav.Link href="/#contact" className="scroller">
                        Contact
                      </Nav.Link>
                      {/* <Link to='/faqs' className='nav-link'>
                        FAQs
                      </Link> */}
                    </Nav>
                  )}
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
