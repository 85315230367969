import React from "react";

import chikkamap from "assets/images/infosys/chikkamap.png";

import { Link } from "gatsby";

export default function Wherewhenchikka() {
  return (
    <div className="wherewhenchika container">
      <div className="row">
        <div className="col-md-12">
          <h3 className="infosys-theme-blue title-theme-heading">
            {" "}
            Restoration Site{" "}
          </h3>
        </div>

        <div className="col-md-4 card where-card" style={{ marginTop: "0px" }}>
          <img
            src={chikkamap}
            className="img-fluid"
            alt="Chikka Thimmasandra"
          />
          <div className="card-body where-body">
            <p className="card-text">
              <span>Chikka Thimmasandra, </span> Anekal Taluk, East Bengaluru
            </p>
            <p className="card-text">
              Target Number of Saplings - <span> 7000 </span>
            </p>
            <p className="card-text">
              <span> 11.5 Acre Lake Site </span>
            </p>

            {/* <p className='card-text'>
              Date of Plantation - <span> 24th Feb, 2022 </span>
            </p> */}
            <div>
              <Link
                className="viewmap-tag infosys-theme-blue"
                to="https://goo.gl/maps/LCyR6jtTV3Mpgmse7"
                target="_blank"
                rel="noreferrer"
              >
                <b> View in Map </b>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
