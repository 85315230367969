import React from "react";

import willexecute from "assets/images/infosys/executionprocess.png";

export default function Projectexecuteinfosys() {
  return (
    <div
      className="project-execute inner-pages bg-light-blue-wr"
      style={{ marginBottom: "30px" }}
    >
      <div className="container">
        <div className="row mobi-column-rever">
          <div className="col-md-7">
            <img
              className="vadora-img"
              src={willexecute}
              alt=" Project Execution In Process"
            />
          </div>
          <div className="col-md-5">
            <h3 className="title-theme-heading infosys-theme-blue">
              Project Execution Stages
            </h3>
            <p className="execute-p-content zscaler-p-tag">
              Consultations were held with villagers to seek their inputs for
              restoring the lake to its original form. A detailed project report
              (DPR) was then prepared and submitted to the local government
              authorities to obtain the necessary approvals for this project.
              Initially, a bowl structured bund was envisaged which needed
              removal of current bund which divided the lake into two bodies.
              Later, we realigned our plan to retain the main bund in order to
              not disturb the existing ecosystem and trees. The current scope of
              work includes deepening the lake, channelizing inlet & outlet
              flows using pipelines, strengthening the bunds, forming a walking
              path and planting trees along the bund side for enhancing
              biodiversity. As of last week of December 2022, the project is
              progressing smoothly and is scheduled to finish by end of January
              2023.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
