import React, { useState, useEffect } from "react";

import { SRLWrapper } from "simple-react-lightbox";

import Carousel from "react-multi-carousel";

import { InfosysitemgalleryImg } from "assets/data/infosys-plantationgallery";

import { slice } from "lodash";

import { Tabs, Tab } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

export default function RecentPlantationsinfosys() {
  const [galleryImages2, setGalleryImages] = useState([]);

  const [key, setKey] = useState("bengaluru");

  useEffect(() => {
    setGalleryImages(slice(InfosysitemgalleryImg, 0, 11));
  }, []);

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="arrow-navigations custom-arrow-navigation-event">
          <button className="pr-custom" onClick={() => previous()}>
            <i className="icon icon-arrow-left-short"></i>
          </button>
          <button className="pr-rightcustom" onClick={() => next()}>
            <i className="icon icon-arrow-right-short"></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="recent-plantation-section bosch-recent-plant hyd-recent-plant inner-pages">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h3
                className="title-theme-heading infosys-theme-blue"
                style={{ textAlign: "left" }}
              >
                {" "}
                Event Photography
              </h3>
            </div>
            <div className="section pad-photography">
              <div className="carousel-section">
                <Tabs
                  defaultActiveKey="bengaluru"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="event-tabs lightbluerecent zscalar-event-tabs"
                >
                  <Tab eventKey="bengaluru">
                    <SRLWrapper>
                      <Carousel
                        arrows={false}
                        autoPlaySpeed={3000}
                        draggable
                        infinite
                        keyBoardControl
                        minimumTouchDrag={80}
                        partialVisible
                        renderButtonGroupOutside={true}
                        swipeable
                        responsive={responsive}
                        customButtonGroup={<ButtonGroup />}
                      >
                        {galleryImages2.map((item) => {
                          return (
                            <div className="img-wrapper" key={item.id}>
                              <img
                                data-src={item.img}
                                className="lazyload"
                                alt={item.alt}
                              />
                              <div className="overlay">
                                <i className="icon icon-arrows-fullscreen"></i>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    </SRLWrapper>
                  </Tab>
                  {/* <Tab eventKey="Pune" title="Pune Office Drive">
                    <Punecarousel />
                  </Tab>
                  <Tab eventKey="hyderabad" title="Hyderabad Office Drive">
                    <Hyderabadcarousel />
                  </Tab>
                  <Tab eventKey="mohali" title="Mohali Office Drive">
                    <Mohalicarousel />
                  </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
