import React from "react";

import { objectforestinfosys } from "assets/data/objectforestinfosys";

export default function Objectforestinfosys() {
  return (
    <div className="objectiveforest inner-pages pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-theme-heading infosys-theme-blue">
              {" "}
              Why this initiative?{" "}
            </h3>
          </div>
          <div className="row object-section-row">
            {objectforestinfosys.map((item) => {
              return (
                <div className="col-md-2 col-sm-4" key={item.id}>
                  <div className="object-box-wrapper">
                    <div className="object-img">
                      <img
                        data-src={item.img}
                        className="lazyload"
                        alt={item.alt}
                      />
                    </div>
                    <div className="object-content1">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
