import React from "react";

import chikkalake from "assets/images/infosys/chikkalake.png";

export default function Infosysbanner() {
  return (
    <div className="infosysbanner pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h3 className="infosys-banner-title">
              {" "}
              Chikka Thimmasandra{" "}
              <span className="dis-block"> Lake Restoration</span>{" "}
            </h3>

            <p className="content-p-text banner-p-content zscaler-p-tag banner-pad-text-60">
              {" "}
              Forests By Heartfulness specializes in the creation of dense mini
              forests in urban & rural areas, primarily to create a favourable
              micro-climate and attract biodiversity benefitting the local
              communities and wildlife alike. FBH also undertakes rejuvenation
              of lakes and ponds in rural areas which has immediate positive
              impact on neighbouring communities.
            </p>

            <p className="content-p-text banner-p-content zscaler-p-tag banner-pad-text-60">
              {" "}
              FBH is now in the process of restoring Chikka Thimmasandra lake
              which is located in Bangalore covering an area of 11.5 acres. It
              is surrounded by many indigenous tree species and attracts several
              birds. Forests by heartfulness started this project in the month
              of October 2022 with a noble cause to safeguard the ecosystem and
              to increase the water holding capacity of the lake, while
              preserving and enhancing the wildlife and biodiversity.
            </p>
          </div>
          <div className="col-lg-6 col-md-6">
            <img className="img-fluid" src={chikkalake} alt="Chikka lake" />
          </div>
        </div>
      </div>
    </div>
  );
}
